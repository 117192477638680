






















































































































































































































import { RequestPaging, timeFormat } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import config from '@/config'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiCommunityList, // 小区列表
    apiGoodsAll,
    apiReportDetailList // 列表
} from '@/api/delivery'

@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    private list: any[] = []
    config = config
    communityList = [] // 小区列表
    goodsList = [] //商品列表
    // 查询条件
    queryObj:any = {
        delivery_date: this.$route.query.delivery_date,
        deliveryman_id: this.$route.query.id,
        community_id: '',
        mobile: '',
        goods_id: '',
        warehouse_id: this.$route.query.warehouse_id,
        status: this.$route.query.status
    }
    timeFormat:any = timeFormat
    pager = new RequestPaging()
    apiReportDetailList = apiReportDetailList
    getList(page?: number): void {
        for(var key in this.queryObj) {
            if(this.queryObj[key] == "") {
                delete this.queryObj[key]
            }
        }

        page && (this.pager.page = page)
        this.pager.request({
            callback: apiReportDetailList,
            params: {
                ...this.queryObj
            }
        })
      
    }
  

    // 获取小区列表
    getcommunityList() {
        apiCommunityList({ page_size: 10000 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            this.communityList = res?.lists

        })
    }
    // 获取产品列表
    getGoodsList() {
        apiGoodsAll().then((res: any) => {
            this.goodsList = res
        })
    }
    // 重置
    handleReset() {
        this.queryObj = {
            delivery_date: this.$route.query.delivery_date,
            deliveryman_id: this.$route.query.id,
            community_id: '', // 小区
            mobile: '',
            goods_id: ''
        }
        this.getList()
    }
    created() {
        let date:any = this.$route.query.delivery_date.toString()
        this.queryObj.delivery_date = timeFormat(date)
        this.getList()
        this.getcommunityList()
        this.getGoodsList()
    }
}
